import React from "react";
import { useInView } from "react-hook-inview";

import { SERVICES } from "../../../src/config/urls";

import TheraphyPink from "./assets/Life-Therapy-color.svg";
import DmePink from "./assets/Life-DME-color.svg";
import RidePink from "./assets/Life-Ride-color.svg";
import HospiceLogo from "./assets/Life-Hospice-color.svg";
import TheraphyGrayBackground from "./assets/lifeTherapyGrayScale.png";
import DurableGrayBackground from "./assets/lifeDMEGrayScale.png";
import RideGrayBackground from "./assets/lifeRideGrayScale.png";
import TheraphyBackground from "./assets/HOMETHERAPYBackground.png";
import DurableBackground from "./assets/DURABLEMEDICALBackground.png";
import RideBackground from "./assets/LIFERIDEBackground.png";
import whitearrow from "./assets/white-arrow.svg";
import HospiceBackground from "./assets/hospice_background.png";
import HospiceGrayBackground from "./assets/hospice-background-w.png";

import {
  CardsSection,
  Card,
  LogoLife,
  CardsContainer,
  CardTextContainer,
  CardTitle,
  CardText,
  ExternalLink,
  MobileCard,
  RedirectMobile,
  CardsSectionMobile,
} from "./styles";

const ServicesCards = () => {
  const [open, setOpen] = React.useState(false);
  const [ref, isVisible] = useInView({
    threshold: 0,
    rootMargin: "0%",
    onEnter: () => {
      /* console.log('enter') */
    },
    unobserveOnEnter: true,
  });

  return (
    <CardsContainer ref={ref}>
      {isVisible && (
        <>
          <CardsSection>
            <ExternalLink href={SERVICES.HOME_THERAPY} target="_blank">
              <Card
                grayImage={TheraphyGrayBackground}
                image={TheraphyBackground}
              >
                <LogoLife
                  src={TheraphyPink}
                  biggerLogo
                  alt="home theraphy logo"
                />
                <CardTextContainer className="CardTextContainer">
                  <CardTitle>
                    LIFE HOME <br /> THERAPY
                  </CardTitle>
                  <CardText>
                    We seek to elevate your day-to-day by providing the
                    assistance and support you need empowering your quality of
                    life.
                  </CardText>
                </CardTextContainer>
              </Card>
            </ExternalLink>
            <ExternalLink href={SERVICES.DME} target="_blank" rel="noreferrer">
              <Card grayImage={DurableGrayBackground} image={DurableBackground}>
                <LogoLife src={DmePink} alt="durable medical equipment logo" />
                <CardTextContainer className="CardTextContainer">
                  <CardTitle lessSize>
                    DURABLE MEDICAL <br /> EQUIPMENT
                  </CardTitle>
                  <CardText>
                    Our DME program is designed with a focus on the health and
                    safety of our clients and is delivered with a passion and
                    commitment to excellence.
                  </CardText>
                </CardTextContainer>
              </Card>
            </ExternalLink>
            <ExternalLink href={SERVICES.RIDE} target="_blank" rel="noreferrer">
              <Card grayImage={RideGrayBackground} image={RideBackground}>
                <LogoLife src={RidePink} alt="ride logo" />
                <CardTextContainer className="CardTextContainer">
                  <CardTitle>LIFE RIDE</CardTitle>
                  <CardText>
                    Wheelchair accessible rides on demand. 100% Secure
                    door-to-door transportation and care.
                  </CardText>
                </CardTextContainer>
              </Card>
            </ExternalLink>
            <ExternalLink href={SERVICES.HOSPICE} target="_blank">
              <Card grayImage={HospiceGrayBackground} image={HospiceBackground}>
                <LogoLife src={HospiceLogo} biggerLogo alt="hospice logo" />
                <CardTextContainer className="CardTextContainer">
                  <CardTitle>LIFE HOSPICE</CardTitle>
                  <CardText>
                    Our mission is to offer compassionate and high-quality care,
                    meeting the medical, spiritual, and emotional needs of
                    patients and families during their end-of-life journey.
                  </CardText>
                </CardTextContainer>
              </Card>
            </ExternalLink>
          </CardsSection>
          <CardsSectionMobile>
            <MobileCard onClick={() => setOpen(!open)}>
              <Card
                grayImage={TheraphyGrayBackground}
                image={TheraphyBackground}
              >
                <LogoLife
                  src={TheraphyPink}
                  biggerLogo
                  alt="home theraphy logo"
                />
                <CardTextContainer className="CardTextContainer">
                  <CardTitle>
                    LIFE HOME <br /> THERAPY
                  </CardTitle>
                  <CardText>
                    We seek to elevate your day-to-day by providing the
                    assistance and support you need empowering your quality of
                    life.
                  </CardText>
                  <RedirectMobile
                    href={SERVICES.HOME_THERAPY}
                    bgImage={whitearrow}
                    target="_blank"
                    rel="noreferrer"
                  />
                </CardTextContainer>
              </Card>
            </MobileCard>
            <MobileCard onClick={() => setOpen(!open)}>
              <Card grayImage={DurableGrayBackground} image={DurableBackground}>
                <LogoLife src={DmePink} alt="durable medical equipment logo" />
                <CardTextContainer className="CardTextContainer">
                  <CardTitle lessSize>
                    DURABLE MEDICAL <br /> EQUIPMENT
                  </CardTitle>
                  <CardText>
                    Our DME program is designed with a focus on the health and
                    safety of our clients and is delivered with a passion and
                    commitment to excellence.
                  </CardText>
                  <RedirectMobile
                    href={SERVICES.DME}
                    bgImage={whitearrow}
                    target="_blank"
                    rel="noreferrer"
                  />
                </CardTextContainer>
              </Card>
            </MobileCard>
            <MobileCard onClick={() => setOpen(!open)}>
              <Card grayImage={RideGrayBackground} image={RideBackground}>
                <LogoLife src={RidePink} alt="ride logo" />
                <CardTextContainer className="CardTextContainer">
                  <CardTitle>LIFE RIDE</CardTitle>
                  <CardText>
                    Wheelchair accessible rides on demand. 100% Secure
                    door-to-door transportation and care.
                  </CardText>
                  <RedirectMobile
                    href={SERVICES.RIDE}
                    bgImage={whitearrow}
                    target="_blank"
                    rel="noreferrer"
                  />
                </CardTextContainer>
              </Card>
            </MobileCard>
            <MobileCard onClick={() => setOpen(!open)}>
              <Card grayImage={HospiceGrayBackground} image={HospiceBackground}>
                <LogoLife src={HospiceLogo} alt="hospice logo" />
                <CardTextContainer className="CardTextContainer">
                  <CardTitle>LIFE HOSPICE</CardTitle>
                  <CardText>
                    Our mission is to offer compassionate and high-quality care,
                    meeting the medical, spiritual, and emotional needs of
                    patients and families during their end-of-life journey.
                  </CardText>
                  <RedirectMobile
                    href={SERVICES.HOSPICE}
                    bgImage={whitearrow}
                    target="_blank"
                    rel="noreferrer"
                  />
                </CardTextContainer>
              </Card>
            </MobileCard>
          </CardsSectionMobile>
        </>
      )}
    </CardsContainer>
  );
};

export default ServicesCards;
