import styled from "styled-components";

import { COLORS } from "../../styles/globals/colors";
import {
  DESKTOP_1200,
  TABLET_992,
  MOBILE_460,
} from "../../styles/globals/sizes";

export const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px auto;
`;

export const CardsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 35px;

  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`;

export const Card = styled.div`
  width: 394px;
  height: 274px;
  margin: 0 30px;
  flex: 1;
  display: flex;
  background-image: url(${(props) => props.grayImage});
  justify-content: center;
  border-radius: 25px;
  padding: 89px 0;
  transition: margin 0.4s ease;

  @media (max-width: ${DESKTOP_1200}px) {
    width: 354px;
    height: 234px;
    padding: 20px 0;
  }

  :active,
  :hover {
    margin: auto 30px;
    padding: 0;
    background-size: cover;
    background-image: url(${(props) => props.image});
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
    transition: all 0.4s ease;

    img {
      display: none;
    }
    .CardTextContainer {
      display: flex;
    }
  }

  @media (max-width: ${TABLET_992}px) {
    width: 394px;
    height: 274px;
    margin: 20px;
    border-radius: 25px;

    :active,
    :hover {
      margin: 20px;
      transform: scale(1.1);
      img {
        display: none;
      }
      .CardTextContainer {
        display: flex;
      }
    }
  }

  @media (max-width: ${MOBILE_460}px) {
    width: 300px;
    height: 150px;
    margin: 10px auto;
    flex: 1;
    display: flex;
    padding: 15px 5px;

    :active,
    :hover {
      width: 300px;
      margin: 10px auto;
      flex: 1;
      padding: 15px 5px;

      border-radius: 25px;
      height: 230px;
      transform: scale(1);
      transition: all 0.2s ease;
      img {
        display: none;
      }
      .CardTextContainer {
        display: flex;
      }
    }
  }
`;

export const MobileCard = styled.div``;

export const LogoLife = styled.img`
  display: flex;
  @media (max-width: ${DESKTOP_1200}px) {
    width: ${(props) => (props.biggerLogo ? "58%" : "45%")};
  }
  @media (max-width: ${MOBILE_460}px) {
    width: ${(props) => (props.biggerLogo ? "80%" : "60%")};
  }
`;

export const CardTextContainer = styled.div`
  display: none;
  flex-direction: column;
  width: 100%;
  padding: 48px 35px 0 35px;

  @media (max-width: ${DESKTOP_1200}px) {
    padding: 25px;
  }
  @media (max-width: ${TABLET_992}px) {
    padding: 48px 35px 0 35px;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding-top: 25px;
  }
`;

export const CardTitle = styled.div`
  font: ${(props) =>
    props.lessSize ? `500 30px/32px "Roboto"` : `500 40px/38px "Roboto"`};
  color: ${COLORS.white};
  @media (max-width: ${DESKTOP_1200}px) {
    font: ${(props) =>
      props.lessSize ? `500 26px/26px "Roboto"` : `500 28px/26px "Roboto"`};
  }
  @media (max-width: ${TABLET_992}px) {
    font: ${(props) =>
      props.lessSize ? `500 30px/32px "Roboto"` : `500 40px/38px "Roboto"`};
  }
  @media (max-width: ${MOBILE_460}px) {
    font: ${(props) =>
      props.lessSize ? `500 24px/26px "Roboto"` : `500 26px/26px "Roboto"`};
  }
`;

export const CardText = styled.div`
  margin-top: 15px;
  color: ${COLORS.white};
  font: 500 16px/20px "Roboto";
  @media (max-width: ${TABLET_992}px) {
    font: 500 18px/20px "Roboto";
  }
  @media (max-width: ${MOBILE_460}px) {
    font: 300 16px/18px "Roboto";
  }
`;

export const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const CardsSectionMobile = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media (max-width: ${TABLET_992}px) {
    display: flex;
  }
`;

export const RedirectMobile = styled.a`
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 15px;
  right: 15px;
  background: ${(props) =>
    props.bgImage ? `url(${props.bgImage}) center/cover no-repeat;` : ``};
  text-decoration: none;
  color: inherit;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
