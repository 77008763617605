import styled from "styled-components";

import { COLORS } from "../../styles/globals/colors";

import {
  DESKTOP_1200,
  TABLET_992,
  TABLET_800,
  TABLET_740,
  MOBILE_460,
} from "../../styles/globals/sizes";

export const CredentialsBackground = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  background: ${COLORS.vermilion};
  padding: 75px 0;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${TABLET_992}px) {
    padding: 60px 0;
  }
  @media (max-width: ${TABLET_800}px) {
    padding: 60px 20px 40px;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding: 50px 20px;
  }
`;

export const CredentialsContainer = styled.div`
  width: fit-content;
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 720px auto;
  grid-auto-flow: row;
  grid-gap: 62px;

  @media (max-width: ${DESKTOP_1200}px) {
    max-width: 945px;
    grid-template-columns: 600px auto;
  }
  @media (max-width: ${TABLET_992}px) {
    grid-template-columns: 520px auto;
    grid-gap: 50px;
  }
  @media (max-width: ${TABLET_800}px) {
    grid-template-columns: none;
    grid-template-rows: 1fr auto;
    grid-gap: 20px;
  }
  @media (max-width: ${MOBILE_460}px) {
    grid-template-columns: 1fr;
  }
`

export const CredentialInfo = styled.div`
  color: ${COLORS.redTitle};
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: ${MOBILE_460}px) {
    display: block;
    text-align: left;
    width: 100%;
    max-width: 100%;
  }
`;

export const CredentialText = styled.p`
  margin: 15px 0;
  font-size: 20px;
  line-height: 30px;
  font-family: Roboto;
  color: ${COLORS.white};
  text-align: left;
  
  & span {
    font-weight: 500;
  }

  @media (max-width: ${DESKTOP_1200}px) {
    font-size: 18px;
    line-height: 26px;
  }
  @media (max-width: ${TABLET_992}px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: ${TABLET_800}px) {
    margin: 0;
    width: 640px;
    font-size: 18px;
    line-height: 26px;
  }
  @media (max-width: ${TABLET_740}px) {
    width: auto;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const CredentialPicture = styled.picture`
  width: fit-content;
  justify-self: center;
  align-self: center;
  filter: grayscale(1) brightness(10);

  & > img, 
  & > source {
    height: 100%;

    @media (max-width: ${DESKTOP_1200}px) {
      height: 150px;
    }
    @media (max-width: ${TABLET_992}px) {
      height: 130px;
    }
    @media (max-width: ${TABLET_740}px) {
      height: 125px;
    }
  }
`;
