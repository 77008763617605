import React, { useState } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import "./defaultStyles.css";
import 'swiper/swiper-bundle.css';

// import required modules
import { Pagination, Autoplay } from "swiper";

import QuotationMarks from './assets/icon-testimonial.svg'
import ArrowIcon from './assets/icon-arrow-back.svg'

import {
TestimonialsContainer, Title, TestimonialCard, SliderContainer, Container, Text, TestimonialName, QuotationMarksIcon, AwardsNavigationButton
} from "./styles";

const Testimonials = () => {

  const [swiperInstance, setSwiperInstance] = useState();

    const SliderControls: React.FC<{side: string}> = (props) => {
        const swiper = useSwiper();
        const handleNext = () => {
          swiperInstance.slideNext();
        }
        const handlePrev = () => {
          swiperInstance.slidePrev();
        }
      
        if (props.side === "right") return (
          <AwardsNavigationButton src={ArrowIcon} type={'next'} onClick={() => handleNext()} />
        )
        if (props.side === "left") return (
          <AwardsNavigationButton src={ArrowIcon} type={'prev'} onClick={() => handlePrev()} />
        )
      }

  return (
  <Container>
    <TestimonialsContainer>
        <Title>
            TESTIMONIALS
        </Title>
        <SliderControls side="left" />
        <SliderControls side="right" />
        <SliderContainer>
        <Swiper
          loop={true}
          autoplay={true}
          centeredSlides={true}
          speed={700}
          slidesPerView={3}
          spaceBetween={0}
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          breakpoints= {{
            210: {
              slidesPerView: 1,
              spaceBetween: 20
            },
            670: {
              slidesPerView: 2,
              spaceBetween: 0
            },
            // when window width is >= 640px
            993: {
              slidesPerView: 3,
             
            }
          }
        }
          pagination={{
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 2
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
            <SwiperSlide>
              <TestimonialCard>
                <QuotationMarksIcon src={QuotationMarks}/>
                <Text>
                I wanted to share with my neighbors my appreciation of LifeHome Care over the past year. They have done a wonderful job by providing me with a caregiver who is extremely professional, thorough, caring , and kind. I highly recommend them to anyone looking for help taking care of a loved one.
                </Text>
                <TestimonialName>
                  Jim Ryan
                </TestimonialName>
              </TestimonialCard>
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialCard>
                <QuotationMarksIcon src={QuotationMarks}/>
                <Text>
                  Val is indispensable, I consider her part of my family. Val is wonderful, and treats me like she would treat her mother. 
                </Text>
                <TestimonialName>
                  Patricia Pistolas
                </TestimonialName>
              </TestimonialCard>
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialCard>
                <QuotationMarksIcon src={QuotationMarks}/>
                <Text>
                  Service is outstanding, Novlette does everything she can to help me. It's been two and a half months since starting the service and I am getting better everyday. I can't praise my aide Novelette enough.
                </Text>
                <TestimonialName>
                  Mr Ardis
                </TestimonialName>
              </TestimonialCard>
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialCard>
                <QuotationMarksIcon src={QuotationMarks}/>
                <Text>
                  My family had a great experience with the care my grandfather received. They have a great team of professionals. I highly recommend this agency!
                </Text>
                <TestimonialName>
                  Franco Burga
                </TestimonialName>
              </TestimonialCard>
              </SwiperSlide>
              <SwiperSlide>
                <TestimonialCard>
                  <QuotationMarksIcon src={QuotationMarks}/>
                  <Text>
                    My Grandparents are being taken care of here, and must I say they are doing a wonderful job. They have a brilliant staff, and I would recommend this place to anyone.
                  </Text>
                  <TestimonialName>
                    Jake Szatkowski
                  </TestimonialName>
                </TestimonialCard>
              </SwiperSlide>
              <SwiperSlide>
               <TestimonialCard>
                  <QuotationMarksIcon src={QuotationMarks}/>
                  <Text>
                    I had my elderly family members taken care of here and they were treated brilliantly. They had no complaints about the service. 10/10 would recommend
                  </Text>
                  <TestimonialName>
                    Robert B
                  </TestimonialName>
              </TestimonialCard>
            </SwiperSlide>
        </Swiper>
        </SliderContainer>
    </TestimonialsContainer>
  </Container>
  );
};

export default Testimonials;
