import hourly_care from "./assets/hourly_care_icon.svg";
import respite_care from "./assets/respite_care_icon.svg";
import transportation from "./assets/transportation_icon.svg";
import case_management from "./assets/case_management_icon.svg";
import overnight_care from "./assets/overnight_care_icon.svg";
import supplemental_care from "./assets/supplemental_care_icon.svg";
import live_in_care from "./assets/live_in_care_icon.svg";
import discharge_coordination from "./assets/discharge_coordination_icon.svg";
import medication_management from "./assets/medication_management_icon.svg";
import companionship from "./assets/companionship.svg";

export const services = [
  {
    img: hourly_care,
    title: "Hourly Care",
    text:
      "Hourly home care allows clients to hire our caregivers on an hourly. By helping with daily activities, our caregivers enable clients to maintain their normal daily routines and age in place.",
  },
  {
    img: respite_care,
    title: "Respite Care",
    text:
      "Respite care supports you and your career by giving you a break for a short period of time. It can help give you the time and space to do things independently, out in the community, or in a care home for the aged.",
  },
  {
    img: case_management,
    title: "Case Management",
    text:
      "We employ the highest quality Case Managers to deliver full geographical coverage and excellent results. Every contact an employee receives is from a clinician specialized in absence management and return to work, ensuring all recommendations are specific and evidence-based.",
  },
  {
    img: overnight_care,
    title: "Overnight Care",
    text:
      "Overnight care is when an expertly trained caregiver stays in your home overnight to meet your normal activities of daily living. Care needs don’t stop at the end of the working day – fortunately for many who need help, neither does caregiving.",
  },
  {
    img: live_in_care,
    title: "Live-In Care",
    text:
      "Live-in care helps you live the life you want on your own terms. By getting to know your preferences and routines, we’ll put a unique package of support in place with a carefully chosen caregiver. Together, we’ll help you stay in the home you know and love.",
  },
  {
    img: medication_management,
    title: "Medication Management",
    text:
      "This is especially important for those taking large numbers of medications to address chronic illnesses and multiple diseases. Taking multiple medications is known as polypharmacy, and it is particularly common among older adults.",
  },
  {
    img: companionship,
    title: "Companionship",
    text:
      "Our aides are experienced caregivers who encourage and assist our clients to exercise, run errands, and provide transportation to appointments. Loneliness is a contributing factor that allows a loved one to decline. Companionship enhances the lives of our seniors and makes a difference.",
  },
  {
    img: discharge_coordination,
    title: "Discharge Coordination",
    text:
      "Transitions between facilities and providers can expose patients to preventable errors and adverse events. Our clinicians are scheduled to be present upon discharge form the hospital or a sub-acute facility to ensure optimal safety and care planning individualized needs.",
  },
  {
    img: transportation,
    title: "Transportation",
    text:
      "Our caregivers are able to take clients to their doctor's appointments, shopping, and social outings.  They are also able to run errands such as grocery shopping or pharmacy trips.",
  },
  {
    img: supplemental_care,
    title: "Supplemental Care",
    text:
      "Supplemental health insurance comes in handy if you're short of cash and facing mounting health care bills. That may very well be the case even if you have health insurance, like Medicare.",
  },
];
