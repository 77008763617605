import styled, { css } from 'styled-components'

import { COLORS } from "../../styles/globals/colors";

import {
  DESKTOP_1366,
  DESKTOP_1200,
  TABLET_992,
  TABLET_768,
} from "../../styles/globals/sizes";

export const Container = styled.div`
  width: 100%;
  background: #F2F2F2 0% 0% no-repeat padding-box;
  max-width: 1500px;
  margin: 0 auto;
  position: relative;
`;

export const TestimonialsContainer = styled.div`
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 70px 0 70px;
  
`;

export const QuotationMarksIcon = styled.img`
  width: 48px;
  height: 48px;
  align-self: center;
  margin-bottom: 28px;
`;

export const Title = styled.div`
  text-align: center;
  font: bold 37px/43px "Roboto";
  color: ${COLORS.redTitle};
  margin-bottom: 43px;
`;

export const Text = styled.div`
  font: italic normal normal 17px/24px "Roboto";
  color: ${COLORS.gray};
`;


export const TestimonialName = styled.div`
  text-align: center;
  font: normal normal bold 16px/17px "Roboto";
  letter-spacing: 0px;
  color: ${COLORS.gray};
  opacity: 1;
  margin-top: 15px;
  height: 100%;
  display: flex;
  align-items: self-end;
  justify-content: center;
`;

export const TestimonialCard = styled.div`
   width: auto;
   max-width: 345px;
   background: #FFFFFF 0% 0% no-repeat padding-box;
   box-shadow: 0px 0px 10px #0000001A;
   border-radius: 6px;
   display: flex !important;
   flex-direction: column;
   padding: 32px 5%;
   height: 100%;

   @media (max-width: 1300px) {
    max-width: 300px;
   }

   @media (max-width: ${TABLET_992}px) {
    max-width: 380px;
   }
   @media (max-width: ${TABLET_768}px) {
    max-width: 320px;
   }
`;

export const SliderContainer = styled.div`
     width: 100%;
     height: 100%;

     .swiper-slide {
      height: auto;
      }
      .swiper{
        padding-bottom: 50px;
      }
      .swiper-pagination{
        bottom: 0;
      }
      span.swiper-pagination-bullet.swiper-pagination-bullet-active {
          background-color: #E01A73;
          opacity: 1;
          width: 12px;
          height: 12px;
        }

      /* target all bullets */
      .swiper-pagination-bullet {
        background-color: #CCCCCC;
        opacity: 1;
        width: 12px;
        height: 12px;
      }
`;

export const AwardsNavigationButton = styled.img`
  width: 14px;
  height: 24px;
  cursor: pointer;
  transform: ${props => (props.type ==='prev') ? 'scale(-1)' : ''};
  font-size: 30px;
  position: absolute;
  top: 43%;
  ${props => (props.type ==='next') ? css`right: 60px` : css`left: 60px` };
  @media(max-width: ${DESKTOP_1366}px) {
    ${props => (props.type ==='next') ? css`right: 10px` : css`left: 10px` };
  }
  @media(max-width: ${DESKTOP_1200}px) {
    display: none;
  }
  @media(max-width: ${TABLET_992}px) {
    display: none;
  }
`

