import React from "react";
import { useInView } from 'react-hook-inview';

import { MapContainer, MultipleLocationsMap } from "./styles";

import { PATHS } from "../../config/urls";

const LifeHomeCareLocation = () => {
  const [ref, isVisible] = useInView({
    threshold: 0,
    rootMargin: '0%',
    onEnter: () => { /* console.log('enter') */ }, 
    unobserveOnEnter: true,
  })
  
  return (
    <MapContainer ref={ref}>
      { isVisible &&
        <MultipleLocationsMap title="Life Home Care locations" src={PATHS.MAP_LINK}></MultipleLocationsMap>
      }
    </MapContainer>
  );
};

export default LifeHomeCareLocation;
