import React from "react";

import { services as data } from "./data";

import {
  HomeCareServices,
  ServicesTitle,
  ServicesText,
  ServicesGrid,
  HomeCareService,
  HomeCareServiceImage,
  HomeCareServiceTextContainer,
  HomeCareServiceTitle,
  HomeCareServiceText,
} from "./styles";

const OurServices = () => {
  return (
    <HomeCareServices>
      <ServicesTitle>OUR SERVICES INCLUDES</ServicesTitle>
      <ServicesText>
        Our home care services are designed to provide an enriching lifestyle
        for our clients, from the comfort of their homes
      </ServicesText>
      <ServicesGrid>
        {data.map((item) => {
          return (
            <HomeCareService key={item.title}>
              <HomeCareServiceImage bgImage={item.img} />
              <HomeCareServiceTextContainer>
                <HomeCareServiceTitle>{item.title}</HomeCareServiceTitle>
                <HomeCareServiceText>{item.text}</HomeCareServiceText>
              </HomeCareServiceTextContainer>
            </HomeCareService>
          );
        })}
      </ServicesGrid>
    </HomeCareServices>
  );
};

export default OurServices;
