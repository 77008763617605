import styled from "styled-components";

export const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 1500px;
  margin: auto;
`;

export const MultipleLocationsMap = styled.iframe`
  width: 100vw;
  height: 500px;
  padding: 0;
  border: none;
`;
